.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    border-radius: 1rem;
    padding: 2rem;
    width: 40%;
    text-align: center;
}
.buttons{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.time{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: rgb(4, 255, 4);
}
button{
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    background-color:rgb(4, 255, 4);
    color: rgb(0, 0, 0);
}
button:hover{
    background-color: rgb(135, 255, 124);
}
h2{
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 300;
    color: rgb(4, 255, 4);

}